@import 'animate.css';

.app__navbar{
    width: 100%; 
    height: inherit; //Todo: set this to 100vh to make it dynamicall on scroll back
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  2rem 2rem;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    z-index: 2;
    position: fixed;
    
}

.app__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items:center;
    
    img{
        width: 100%;
        height: 5rem;
        
        @media screen and (min-width: 2000px) {
            width:100%;
            height: 80px;
        }
        
    }
    
}

.app__navbar-links{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    
    li{
       
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;
        div{
            width: 0.35rem;
            height: 0.35rem;
            background: transparent;
            border-radius: 50%;
            margin-bottom: 0.35rem;
            

        }

        a{
            color: var(--gray-color);
            text-decoration: none;
            text-transform: uppercase;
            flex-direction: column;
            font-weight: 500;
            transition: all 0.5 ease-in-out;
            
            font-size: larger;
            &:hover {
                font-weight: 1000;
                font-size: x-large;
                
                
                color: var(--secondary-color);
            }
        }

          
        &:hover{
            div {
                animation: bounceIn; 
                animation-duration: 0.158s; 
                --animate-repeat: infinite;
                transform: scale(1.8); 
                background: var(--secondary-color);
                
            }
        }
    
    }
    @media screen and (max-width: 900px) 
    {
        display: none;
    }
}

.app__navbar-menu{
    display: flex;
    justify-content: center;
    width: 2.188rem;
    height: 2.188rem;


    border-radius: 50%;
    position: relative;
    align-items: center;
    background-color: var(--secondary-color);
    
    &:hover {
       
        background-color: var(--gray-color);        
    
    }  

    svg {
        width: 70%;
        height: 70%;
        color: var(--white-color);
        
    }

    div {

        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;

        padding: 1rem;

        width: 80%;
        height: 100vh;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;


        background: url('../../assets/bgWhite.png');
        background-color: var(--white-color);
        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20pw rgba(168,168,168,0.15);

        svg{
            width: 2.188rem;
            height: 2.188rem;
            color: var(--secondary-color);
            margin: 0.5rem 1rem;
            
            &:hover {
                display: flex;
                color: var(--gray-color);
                animation: pulse;
                animation-duration: 1.2s;
                
            
            }  
        }

        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }

        li {
            margin: 1rem;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
            a {
                color: var(--gray-color); 
                animation: fadeInRight;
                animation-duration: 2.4s; 
                --animate-repeat: infinite;
                text-decoration: none; 
                font-size: 1rem;
                text-transform: uppercase;
                
                &:hover {
                    display: flex;
                    color: var(--secondary-color);
                    animation: pulse;
                    animation-duration: 1.2s;
                    font-size: 2.5rem;
                
                }  

            }

                 
        }
        
    }
    
}